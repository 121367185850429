import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./redux/api/apiSlice";
import authSliceReducer from "./redux/auth/authSlice";
import layoutSliceReducer from "./redux/layout/layoutSlice";
import loginSliceReducer from "./redux/login/authSlice";
import patientSliceReducer from "./redux/app/patientSlice";
import assessmentSliceReducer from "./redux/app/assessmentSlice";
import ecommerceSliceReducer from "./redux/ecommerce/ecommerceSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSliceReducer,
    layout: layoutSliceReducer,
    login: loginSliceReducer,
    ecommerce: ecommerceSliceReducer,
    patient: patientSliceReducer,
    assessment: assessmentSliceReducer
  },
  // devTools: process.env.NODE_ENV !== "production",
  devTools: window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(apiSlice.middleware),
});

import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Templates = lazy(() => import("./Templates"));
const PatientWrapper = lazy(() => import("./patients/PatientWrapper"));
const PatientSummary = lazy(() => import("./patients/PatientSummary"));
const Patients = lazy(() => import("./Patients"));
const Activities = lazy(() => import("./Activities"))
const Activity = lazy(() => import("./activities/Activity"))
const ActivitySummary = lazy(() => import("./activities//ActivitySummary"))
const SessionNotes = lazy(() => import("./SessionNotes"))
const SessionNote = lazy(() => import("./notes/SessionNote"))
const SessionNoteSummary = lazy(() => import("./notes/SessionNoteSummary"))

const CustomTemplate = lazy(() => import("./Templates/CustomTemplate"))
const StandardizedTestWrapper = lazy(() => import("./Templates/StandardizedTestWrapper"))
const TemplateDetails = lazy(() => import("./Templates/TemplateDetails"))

const ProgressReport = lazy(() => import("./Reports/ProgressReport"))
const TextReportWrapper = lazy(() => import("./Reports/TextReportWrapper"))
const PatientReports = lazy(() => import("./Reports/PatientReports"))

const mvpRoutes = [
  {
    path: "/templates",
    element: <Templates />,
    auth: authRoles.editor,
  },
  {
    path: '/templates/add',
    element: <CustomTemplate />,
    auth: authRoles.editor,
  },
  {
    path: '/templates/:templateId',
    element: <TemplateDetails />,
    auth: authRoles.editor,
  },
  {
    path: "/patients",
    element: <Patients />,
    auth: authRoles.guest,
  },
  {
    path: '/patients/add',
    element: <PatientWrapper />,
    auth: authRoles.editor,
  },
  {
    path: '/patients/edit/:patientId',
    element: <PatientWrapper />,
    auth: authRoles.editor,
  },
  {
    path: '/patients/:patientId',
    element: <PatientSummary />,
    auth: authRoles.guest,
  },
  {
    path: '/patients/:patientId/activities',
    element: <Activities />,
    auth: authRoles.guest,
  },
  {
    path: '/patients/:patientId/activities/add',
    element: <Activity />,
    auth: authRoles.editor,
  },
  {
    path: '/patients/:patientId/activities/:activityId',
    element: <ActivitySummary />,
    auth: authRoles.guest,
  },
  {
    path: '/patients/:patientId/activities/edit/:activityId',
    element: <Activity />,
    auth: authRoles.editor,
  },
  {
    path: '/patients/:patientId/sessions',
    element: <SessionNotes />,
    auth: authRoles.guest,
  },
  {
    path: '/patients/:patientId/sessions/add',
    element: <SessionNote />,
    auth: authRoles.editor,
  },
  {
    path: '/patients/:patientId/sessions/:noteId',
    element: <SessionNoteSummary />,
    auth: authRoles.guest,
  },
  {
    path: '/patients/:patientId/sessions/edit/:noteId',
    element: <SessionNote />,
    auth: authRoles.editor,
  },
  {
    path: "/standardizedTest/:code",
    element: <StandardizedTestWrapper />,
    auth: authRoles.editor,
  },
  {
    path: "/patients/:patientId/progressReport",
    element: <ProgressReport/>,
    auth: authRoles.editor,
  },
  {
    path: "/progressReports",
    element: <ProgressReport/>,
    auth: authRoles.editor,
  },
  {
    path: "/reports",
    element: <PatientReports isTemplate={false} key="custom" />,
    auth: authRoles.editor,
  },
  {
    path: "/reports/templates",
    element: <PatientReports isTemplate={true} key="template" />,
    auth: authRoles.editor,
  },
  {
    path: "/reports/add",
    element: <TextReportWrapper/>,
    auth: authRoles.editor,
  },
  {
    path: "/reports/:reportId",
    element: <TextReportWrapper/>,
    auth: authRoles.editor,
  }
];

export default mvpRoutes;
import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Dashboard1 = lazy(() => import("./dashboard1/Dashboard1"));
const Dashboard2 = lazy(() => import("./dashboard2/Dashboard2"));

const dashboardRoutes = [
  {
    path: "/dashboard",
    element: <Dashboard1 />,
    auth: authRoles.editor,
  },
  // {
  //   path: "/dashboard/v2",
  //   element: <Dashboard2 />
  // },
];

export default dashboardRoutes;

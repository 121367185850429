import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUserData } from "../redux/auth/authSlice";
import jwtAuthService from "../services/jwtAuthService";
import localStorageService from "../services/localStorageService";
// import firebaseAuthService from "../services/firebase/firebaseAuthService";

const Auth = ({ children }) => {
  const dispatch = useDispatch();
  const checkJwtAuth = () => {
    let authUser = localStorageService.getItem("auth_user")
    let token = localStorageService.getItem("jwt_token");

    if(!token) return;

    jwtAuthService.loginWithToken(token, authUser).then((user) => {
      dispatch(setUserData(user));
    });
  };

  // const checkFirebaseAuth = () => {
  //   firebaseAuthService.checkAuthStatus((user) => {
  //     if (user) {
  //       console.log(user.uid);
  //       console.log(user.email);
  //       console.log(user.emailVerified);
  //     } else {
  //       console.log("not logged in");
  //     }
  //   });
  // };

  useEffect(() => {
    // checkJwtAuth();
    // checkFirebaseAuth();
  }, []);

  checkJwtAuth();

  return <>{children}</>;
};

export default Auth;
import { flat } from "@utils";
import routes from "app/routes";
import { useSelector } from "react-redux";
import GullLayout from "app/GullLayout/GullLayout";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { matchPath } from "react-router";
import localStorageService from "../services/localStorageService";

const getUserRoleAuthStatus = (pathname, user, routes) => {
  if (!user) return false;
  const matched = routes
    .filter((r) => r.path)
    .find((r) => matchPath(r.path, pathname));

  return (
    matched &&
    (!(matched.auth && matched.auth.length) || matched.auth.includes(user.role))
  );
};

const AuthGuard = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const { pathname } = useLocation();
  // const [previousRoute, setPreviousRoute] = useState(null);

  const isUserRoleAuthenticated = getUserRoleAuthStatus(
    pathname,
    user,
    flat(routes.routes)
  );

  let authenticated = isAuthenticated && isUserRoleAuthenticated;

  // IF YOU NEED ROLE BASED AUTHENTICATION,
  // UNCOMMENT ABOVE TWO LINES, getUserRoleAuthStatus METHOD AND user VARIABLE
  // AND COMMENT OUT BELOW LINE

  // let authenticated = isAuthenticated

  // useEffect(() => {
  //   if (previouseRoute !== null) setPreviousRoute(pathname);
  // }, [pathname, previouseRoute]);

  let token = localStorageService.getItem("jwt_token");

  useEffect(() => {
    if (!authenticated && !token) {
      navigate("/sessions/signin");
    }
  }, []);

  return (
    authenticated && (
      <GullLayout>
        <Outlet />
      </GullLayout>
    )
  );
};

export default AuthGuard;

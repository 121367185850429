import Mock from "../mock";

const skillsDB = {
  skillsAssessment: [
    {
      type: "radio",
      section: "Playground Access",
      label: "Navigation of play structure",
      options: "Independent;With supervision;With physical assistance;Not able",
      _code: "playground_navigation",
    },
    {
      type: "multiple",
      section: "Playground Access",
      label: "Use at recess",
      comments: true,
      other: true,
      options: "Climb ladder up/down;Slide;Monkey bars;Steps;Rock wall",
      _code: "playground_recess",
      itemAnswer: "Able;Unable",
    },
    {
      type: "text",
      section: "Playground Access",
      label: "Recess activities observed",
      placeholder: "Fill in: Student was observed ________ at recess.",
      _code: "playground_recess_observed",
    },
    {
      type: "multiple",
      section: "Physical Education (PE) Access",
      label: "Participation concerns",
      options: "No",
      placeholder: "Concerns? specify below.",
      _code: "pe_concerns",
    },
    {
      type: "multiple",
      section: "Physical Education (PE) Access",
      comments: true,
      label: "Participation concerns - specifics",
      _code: "pe_concerns_specifics",
      options:
        "Attention difficulty;Motor coordination difficulty;Difficulty understanding directions;Decreased strength;Impaired vision;Low endurance",
    },
    {
      type: "multiple",
      section: "Physical Education (PE) Access",
      label: "Observed PE strengths",
      comments: true,
      other: true,
      _code: "pe_concerns_strengths",
      options:
        "Hop on each foot;Gallop;Jumping jacks;Single leg balance;Skip;Kick a moving ball;Throw a ball accurately",
    },
    {
      type: "radio",
      section: "Classroom, Campus Navigation",
      label: "Navigate surfaces",
      comments: true,
      _code: "classroom_navigate",
      options: "Independent;With supervision;With physical assistance",
    },
    {
      type: "multiple",
      section: "Classroom, Campus Navigation",
      label: "Navigate in school",
      _code: "school_navigate",
      options:
        "Find needed items;Move around obstacles such as chairs or desks;Stand and walk in a line with peers;Locate rooms (office, nurse, bathroom)",
    },
    {
      type: "multiple",
      section: "Classroom, Campus Navigation",
      label: "Unable to navigate in school",
      comments: true,
      _code: "school_navigate_unable",
      options:
        "Find needed items;Move around obstacles such as chairs or desks;Stand and walk in a line with peers;Locate rooms (office, nurse, bathroom)",
    },
    {
      type: "multiple",
      section: "Classroom, Campus Navigation",
      label: "Transition carrying items",
      options: "Small items;Backpack;Lunch tray;Books",
      _code: "classroom_transition",
      other: true,
    },
    {
      type: "multiple",
      section: "Postural Skills for Educational Activities",
      label: "Chair and desk",
      options: "Appropriate height;Accessible;Functional location",
      _code: "postural_desk",
      comments: true,
    },
    {
      type: "radio",
      section: "Postural Skills for Educational Activities",
      label: "Sitting in a chair",
      _code: "postural_chair",
      options:
        "Maintain a seated position;Frequently changed positions;Frequently left seat;Often stood while completing tasks",
      comments: true,
    },
    {
      type: "radio",
      section: "Postural Skills for Educational Activities",
      label: "Sitting on the floor",
      _code: "postural_floor",
      options:
        "Cross-legged;Frequently changed positions but maintained personal space;Difficulty sitting still and maintaining personal space;Sat in a 'W' position",
      comments: true,
    },
    {
      type: "radio",
      section: "Classroom Tool Use",
      label: "Handedness",
      _code: "tool_handedness",
      options:
        "Right functional;Right dysfunctional;Right immature;Left functional;Left dysfunctional;Left immature;Not determined",
    },
    {
      type: "multiple",
      section: "Classroom Tool Use",
      label: "Pencil grasp and pressure",
      _code: "tool_grasp",
      comments: true,
      options:
        "Radial cross palmar grasp;Palmar supinated grasp;Digital pronate grasp (only index finger extended);Brush grasp;Grasp with extended fingers;Cross thumb grasp;Static tripod grasp;Four finger grasp;Lateral tripod grasp;Dynamic tripod grasp;Adequate pressure;Light pressure;Heavy pressure;Thumb wrap grasp",
    },
    {
      type: "multiple",
      section: "Classroom Tool Use",
      label: "Open webspace?",
      _code: "tool_webspace",
      options: "Yes",
    },
    {
      type: "radio",
      section: "Classroom Tool Use",
      comments: true,
      label: "Did student's opposite hand stabilize paper?",
      _code: "tool_paper_stabilize",
      options: "Independent;Needs prompting;Right immature;No",
    },
    {
      type: "radio",
      section: "Classroom Tool Use",
      label: "Scissor skills",
      comments: true,
      _code: "tool_scissor",
      options:
        "Not able;Able regular scissors independently;Able regular scissors assistance;Able modified scissors independently;Able modified scissors assistance",
    },
    {
      type: "multiple",
      section: "Classroom Tool Use",
      label: "Cutting",
      comments: true,
      _code: "tool_cutting",
      options:
        "Small cuts;Straight;Curvy;Zigzag;Simple geometric shapes (circle, square, triangle;Irregular complex shapes",
      itemAnswer: "Able;Unable",
    },
    {
      type: "multiple",
      section: "Classroom Tool Use",
      label: "Other tools",
      comments: true,
      _code: "tool_other",
      options: "Stapler;Tape dispenser;Pencil sharpener;Hole puncher",
      itemAnswer: "Able;Unable",
    },
    {
      type: "radio",
      section: "Classroom Tool Use",
      label: "Coloring skills",
      _code: "tool_coloring_skills",
      options: "Scribbles;Colors <70%;Colors 70-85%;Colors 85-100%",
    },
    {
      type: "radio",
      section: "Classroom Tool Use",
      label: "Coloring within the lines",
      comments: true,
      _code: "tool_coloring_lines",
      options:
        "Independently;Minimal (<3) deviations (1/8' outside of lines);Moderate (3-5) deviations (1/8' outside of lines);Maximum (>5) deviations (1/8' outside of lines)",
    },
    {
      type: "radio",
      section: "Classroom Tool Use",
      label: "Can the student draw a person?",
      options: "No;Yes",
      comments: true,
      _code: "tool_draw_person",
      children: {
        type: "text",
        label: "Number of body parts",
      },
    },
    {
      type: "multiple",
      section: "Classroom Tool Use",
      label: "Drawing skills",
      comments: true,
      _code: "tool_drawing",
      options:
        "Vertical line;Horizontal line;Diagonal line;Cross;Triangle;Square;Diamond;Circle",
      itemAnswer: "Able;Unable",
    },
    {
      type: "multiple",
      section: "Technology Accessibility",
      label: "Tech uses",
      comments: true,
      _code: "tech_able",
      options:
        "Use a keyboard;Use a mouse;Access applications or programs;Use a tablet or iPad",
      itemAnswer: "Able;Unable",
    },
    {
      type: "radio",
      section: "Technology Accessibility",
      label: "Typing method",
      _code: "tech_typing_method",
      options: "Single hand method;Hunt and peck;Most fingers;Home row",
    },
    {
      type: "radio",
      section: "Technology Accessibility",
      label: "Preference",
      _code: "tech_preference",
      options: "Writing;Typing;Neither",
    },
    {
      type: "number",
      section: "Technology Accessibility",
      _code: "tech_words_per_minute",
      label: "Typing words per minute - copying",
    },
    {
      type: "text",
      section: "Technology Accessibility",
      label: "Typing accuracy - copying",
      comments: true,
      _code: "tech_typing_accuracy",
      placeholder: "Fill in: Student typing accuracy from near sight is _____.",
    },
    {
      type: "radio",
      section: "Written Expression",
      label: "Functional written output",
      comments: true,
      _code: "written_functional",
      options:
        "Functional;Can write but not functional;Can write but not consistently;Can't write at all;Can write his first name;Can print his first and last name",
    },
    {
      type: "radio",
      section: "Written Expression",
      label: "Writing speed",
      _code: "written_speed",
      comments: true,
      options: "Writes too quickly;Writes too slowly;Average",
    },
    {
      type: "multiple",
      section: "Written Expression",
      label: "Writing organization",
      options:
        "Identify where to start and stop on writing paper;Write from left to right;Work top to bottom;Generate his/her own ideas",
    },
    {
      type: "multiple",
      section: "Written Expression",
      label: "Sentences assistance",
      _code: "written_assistance",
      comments: true,
      options:
        "Spelling assistance;Dictation;Verbal prompts for punctuation;Highlighted lines or visuals",
    },
    {
      type: "radio",
      section: "Written Expression",
      label:
        "Write uppercase and lowercase letters legibly from memory (letters could be identified as the intended letter)",
      specify: true,
      _code: "written_letters",
      options: "Yes;No(specify below)",
    },
    {
      type: "text",
      section: "Written Expression",
      comments: true,
      _code: "written_letters_unable",
      label: "Letters student cannot write",
    },
    {
      type: "radio",
      section: "Written Expression",
      label: "Can write all numbers independently?",
      specify: true,
      _code: "written_numbers",
      options: "Yes;No(specify below)",
    },
    {
      type: "text",
      section: "Written Expression",
      comments: true,
      _code: "written_numbers_unable",
      label: "Numbers student cannot write",
    },
    {
      type: "multiple",
      section: "Written Expression",
      label: "Writing - inconsistencies",
      comments: true,
      _code: "written_inconsistencies",
      options:
        "Spacing;Size;Letter formation;Line orientation;Use of capital letters;Reversals",
    },
    {
      type: "percentage",
      section: "Written Expression",
      _code: "written_near_sight",
      label: "Copy - Near sight accuracy %",
    },
    {
      type: "percentage",
      section: "Written Expression",
      comments: true,
      _code: "written_far_sight",
      label: "Copy - Far sight accuracy %",
    },
  ],
  fineMotorBOT2: [
    {
      type: "multiple",
      section: "Intro",
      label: "Was the student able to complete the standardized assessment?",
      options: "Yes",
      _code: "fineMotorBOT2_success",
    },
    {
      type: "multiple",
      section: "Intro",
      label: "Why couldn't the student complete a standardized assessment?",
      options:
        "Refused;Couldn't follow standardized questions and directions;Didn't understand the expectations of the tasks",
      _code: "fineMotorBOT2_failure_reason",
    },
    {
      type: "radio",
      section: "Intro",
      label: "Total Motor Composite",
      options:
        "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
      _code: "total_motor_composite",
    },
    {
      type: "radio",
      section: "Intro",
      label: "(BOT-2) Fine Motor Composite",
      options:
        "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
      _code: "fine_motor_composite",
    },
    {
      type: "radio",
      section: "Fine Manual Control",
      label: "(BOT-2) Fine Manual Control",
      options:
        "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
      _code: "fine_manual_control",
    },
    {
      type: "radio",
      section: "Fine Manual Control",
      label: "(BOT-2) Fine Motor Precision subtest",
      options:
        "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
      _code: "fine_motor_precision",
    },
    {
      type: "multiple",
      section: "Fine Manual Control",
      label: "(BOT-2) Color in shape",
      options: "Filling in a circle;Filling in a star",
      _code: "shape_coloring",
      itemAnswer: "Able;Unable",
    },
    {
      type: "multiple",
      section: "Fine Manual Control",
      label: "(BOT-2) Draw a line",
      options: "Crooked line;Curved line",
      _code: "line_drawing",
      itemAnswer: "Able;Unable",
    },
    {
      type: "multiple",
      section: "Fine Manual Control",
      label: "(BOT-2) Other",
      options: "Connecting dots;Folding paper;Cutting circle",
      _code: "bot2_other",
      itemAnswer: "Able;Unable",
    },
    {
      type: "radio",
      section: "Fine Manual Control",
      label: "(BOT-2) Fine Motor Integration subtest",
      options:
        "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
      _code: "fine_motor_integration_subtest",
    },
    {
      type: "multiple",
      section: "Fine Manual Control",
      label: "(BOT-2) Copying",
      options:
        "Circle;Square;Overlapping circles;Wavy line;Triangle;Diamond;Star;Overlapping Pencils",
      _code: "copying_strengths",
      itemAnswer: "Able;Unable",
    },
    {
      type: "radio",
      section: "Manual Coordination",
      label: "(BOT-2) Manual Coordination",
      options:
        "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
      _code: "manual_coordination",
    },
    {
      type: "radio",
      section: "Manual Coordination",
      label: "(BOT-2) Manual Dexterity subtest",
      options:
        "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
      _code: "manual_dexterity_subtest",
    },
    {
      type: "multiple",
      section: "Manual Coordination",
      label: "(BOT-2) Manual Dexterity",
      options:
        "Making Dots;Transferring Pennies;Pegs in pegboard;Sorting cards;Stringing blocks",
      _code: "manual_dexterity_strengths",
      itemAnswer: "Able;Unable",
    },
    {
      type: "radio",
      section: "Manual Coordination",
      label: "(BOT-2) Upper-Limb Coordination subtest",
      options:
        "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
      _code: "upper_link_coordination",
    },
    {
      type: "multiple",
      section: "Manual Coordination",
      label: "(BOT-2) Upper-Limb Coordination",
      options:
        "Dropping/catching ball both hands;Catching a tossed ball;Drop/catching ball one hand;Tossed ball one hand;Dribbling one hand;Dribbling alternating;Throwing a ball",
      _code: "upper_link_coordination_strengths",
      itemAnswer: "Able;Unable",
    },
    {
      type: "radio",
      section: "Body Coordination",
      label: "(BOT-2) Body Coordination",
      options:
        "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
      _code: "body_coordination",
    },
    {
      type: "radio",
      section: "Body Coordination",
      label: "(BOT-2) Bilateral Coordination",
      options:
        "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
      _code: "bilateral_coordination",
    },
    {
      type: "multiple",
      section: "Body Coordination",
      label: "(BOT-2) Bilateral Coordination",
      options:
        "Jumping in place - opposite sides synchronized;Tapping feet and fingers- opposite side;Pivoting thumbs and index fingers;Jumping jacks;Jumping in place - same side;Touching nose eyes closed;Tapping feet and fingers - same side",
      _code: "bilateral_coordination_strength",
      itemAnswer: "Able;Unable",
    },
    {
      type: "radio",
      section: "Body Coordination",
      label: "(BOT-2) Balance Subtest",
      options:
        "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
      _code: "Balance",
    },
    {
      type: "multiple",
      section: "Body Coordination",
      label: "(BOT-2) Balance",
      options:
        "Walking on a line;Walking on a balance beam;Standing on one leg - eyes open;Standing on one leg - eyes closed;Standing on one leg balance beam - eyes open;Standing on one leg balance beam - eyes closed",
      _code: "Balance_strength",
      itemAnswer: "Able;Unable",
    },
    {
      type: "radio",
      section: "Strength and Agility",
      label: "(BOT-2) Strength and Agility",
      options:
        "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
      _code: "agility",
    },
    {
      type: "radio",
      section: "Strength and Agility",
      label: "(BOT-2) Running Speed and Agility",
      options:
        "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
      _code: "running_speed",
    },
    {
      type: "multiple",
      section: "Strength and Agility",
      label: "(BOT-2) Running Speed",
      options:
        "One-legged side hop;Two-legged side hop;One-legged stationary hop;Shuttle run;Stepping sideways over a beam",
      _code: "running_speed_strength",
      itemAnswer: "Able;Unable",
    },
    {
      type: "radio",
      section: "Strength and Agility",
      label: "(BOT-2) Running Subtest",
      options:
        "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
      _code: "strength_subtest",
    },
    {
      type: "multiple",
      section: "Strength and Agility",
      label: "(BOT-2) Agility",
      options:
        "Push-ups;Knee push-ups;Sit-ups;Push-ups and sit-ups;Jump forward",
      _code: "bot2_strength",
      itemAnswer: "Able;Unable",
    },
  ],
  fineMotorPDMS2: [
    {
      type: "radio",
      section: "Quotients",
      label: "(PDMS-2) Total Motor Quotient (TMQ)",
      options:
        "Very Superior;Superior;Above Average;Average;Below Average;Poor;Very Poor",
      _code: "tmq",
    },
    {
      type: "radio",
      section: "Quotients",
      label: "(PDMS-2) Gross Motor Quotient",
      options:
        "Very Superior;Superior;Above Average;Average;Below Average;Poor;Very Poor",
      _code: "gmq",
    },
    {
      type: "radio",
      section: "Quotients",
      label: "(PDMS-2) Fine Motor Quotient",
      options:
        "Very Superior;Superior;Above Average;Average;Below Average;Poor;Very Poor",
      _code: "fmq",
    },
    {
      type: "radio",
      section: "Quotients",
      label: "(PDMS-2) Reflexes",
      options:
        "Very Superior;Superior;Above Average;Average;Below Average;Poor;Very Poor",
      _code: "reflexes",
    },
    {
      type: "multiple",
      section: "Quotients",
      label: "(PDMS-2) Reflexes",
      options: "Walking reflex;Position reflex;Landau reaction",
      _code: "reflexes_strength",
      itemAnswer: "Able;Unable",
    },
    {
      type: "multiple",
      section: "Quotients",
      label: "(PDMS-2) Protecting Reaction",
      options: "Forward;Side;Forward (sitting);Backward",
      _code: "strength_protection",
      itemAnswer: "Able;Unable",
    },
    {
      type: "radio",
      section: "Quotients",
      label: "(PDMS-2) Stationary",
      options:
        "Very Superior;Superior;Above Average;Average;Below Average;Poor;Very Poor",
      _code: "Stationary",
    },
    {
      type: "multiple",
      section: "Quotients",
      label: "(PDMS-2) Stationary",
      options: "Stand on 1 foot;Stand on tiptoes;Imitating movements",
      _code: "strength_stationary",
      itemAnswer: "Able;Unable",
    },
    {
      type: "multiple",
      section: "Quotients",
      label: "(PDMS-2) Sit-ups and Push-ups",
      options: "Sit-ups;Push-ups",
      _code: "strength_situps",
      itemAnswer: "Able;Unable",
    },
    {
      type: "radio",
      section: "Locomotion",
      label: "(PDMS-2) Locomotion",
      options:
        "Very Superior;Superior;Above Average;Average;Below Average;Poor;Very Poor",
      _code: "Locomotion",
    },
    {
      type: "multiple",
      section: "Locomotion",
      label: "(PDMS-2) Walking",
      options: "Walk backwards;Walk sideways;Walk line",
      _code: "strengths_walking",
      itemAnswer: "Able;Unable",
    },
    {
      type: "multiple",
      section: "Locomotion",
      label: "(PDMS-2) Jumping",
      options:
        "Jump forward - feet together;Jump up - feet together;Jump down - one foot lead;Jump down - two-footed;Jumping hurdles;Jumping forward on 1 foot;Turning jump;Jumping sideways",
      _code: "strengths_jumping",
      itemAnswer: "Able;Unable",
    },
    {
      type: "multiple",
      section: "Locomotion",
      label: "(PDMS-2) Walking up stairs",
      options:
        "Both feet with support;Both feet without support;1 foot on each step with support;1 foot on each step without support",
      _code: "strengths_walkingup_stairs",
      itemAnswer: "Able;Unable",
    },
    {
      type: "multiple",
      section: "Locomotion",
      label: "(PDMS-2) Walking down stairs",
      options:
        "Both feet with support;Both feet without support;1 foot on each step with support;1 foot on each step without support",
      _code: "strengths_walkingdown_stairs",
      itemAnswer: "Able;Unable",
    },
    {
      type: "multiple",
      section: "Locomotion",
      label: "(PDMS-2) Other",
      options: "Hopping;Running;Galloping;Skipping",
      _code: "Locomotion_strengths_other",
      itemAnswer: "Able;Unable",
    },
    {
      type: "radio",
      section: "Object Manipulation",
      label: "(PDMS-2) Object Manipulation",
      options:
        "Very Superior;Superior;Above Average;Average;Below Average;Poor;Very Poor",
      _code: "Object_Manipulation",
    },
    {
      type: "multiple",
      section: "Object Manipulation",
      label: "(PDMS-2) Catching ball",
      options:
        "Corrals ball;Secures ball to chest;Catches with hands and arms extended;Catches with hands",
      _code: "strength_catching_ball",
      itemAnswer: "Able;Unable",
    },
    {
      type: "multiple",
      section: "Object Manipulation",
      label: "(PDMS-2) Throwing ball",
      options:
        "Throws ball by extending arm at shoulder;Throws ball by moving arm upward and back;Throws ball by moving arm down and back;Throws ball by moving arm up and back using upper trunk rotation",
      _code: "strength_throwing_ball",
      itemAnswer: "Able;Unable",
    },
    {
      type: "multiple",
      section: "Object Manipulation",
      label: "(PDMS-2) Hitting a target",
      options: "Using underhand toss;Using overhand toss",
      _code: "strength_hitting_Target",
      itemAnswer: "Able;Unable",
    },
    {
      type: "multiple",
      section: "Object Manipulation",
      label: "(PDMS-2) Kicking ball",
      options:
        "Lifts foot and contacts ball;Kicks a moving ball;Kicks a moving ball with minimal deviation;Kicks a moving ball with opposing arm and leg movement;Kicks a ball in the air",
      _code: "strength_kicking_ball",
      itemAnswer: "Able;Unable",
    },
    {
      type: "multiple",
      section: "Object Manipulation",
      label: "(PDMS-2) Bouncing ball",
      options: "Bounces ball and hits wall;Bounces and catches a ball",
      _code: "strength_bouncing_ball",
      itemAnswer: "Able;Unable",
    },
    {
      type: "radio",
      section: "Grasping",
      label: "(PDMS-2) Grasping",
      options:
        "Very Superior;Superior;Above Average;Average;Below Average;Poor;Very Poor",
      _code: "grasping",
    },
    {
      type: "multiple",
      section: "Grasping",
      label: "(PDMS-2) Grasping",
      options:
        "Grasp small items using a raking motion;Grasp items with pincer grasp;Grasp two cubes with one hand;Appropriate marker grasp;Unbuttoning buttons;Buttoning buttons;Opposition",
      _code: "strength_grasping",
      itemAnswer: "Able;Unable",
    },
    {
      type: "text",
      section: "Grasping",
      label: "(PDMS-2) Manual Dexterity: Impact on education",
      _code: "manual_dexterity",
    },
    {
      type: "radio",
      section: "Visual Motor Integration",
      label: "(PDMS-2) Visual Motor Integration",
      options:
        "Very Superior;Superior;Above Average;Average;Below Average;Poor;Very Poor",
      _code: "Visual_Motor_Integration",
    },
    {
      type: "multiple",
      section: "Visual Motor Integration",
      label: "(PDMS-2) Blocks",
      options: "Tower;Train;Bridge;Wall;Steps;Pyramid",
      _code: "Strengths_Blocks",
      itemAnswer: "Able;Unable",
    },
    {
      type: "multiple",
      section: "Visual Motor Integration",
      label: "(PDMS-2) Imitation",
      options: "Vertical Strokes;Horizontal Strokes;Circle;Crossl;Square",
      _code: "Strengths_Imitation",
      itemAnswer: "Able;Unable",
    },
    {
      type: "multiple",
      section: "Visual Motor Integration",
      label: "(PDMS-2) Cutting",
      options: "Snips;Cut paper in half;Line;Circle;Square",
      _code: "Strengths_Imitation",
      itemAnswer: "Able;Unable",
    },
    {
      type: "multiple",
      section: "Visual Motor Integration",
      label: "(PDMS-2) Other",
      options:
        "String beads;Remove top;Fold paper;Drop pellets;Tracing line;Connecting dots;Insert puzzle;Peg board",
      _code: "vmi_Strengths_Other",
      itemAnswer: "Able;Unable",
    },
  ],
};

Mock.onGet("/api/skills/skillsAssessment").reply((config) => {
  return [200, skillsDB.skillsAssessment];
});

Mock.onGet("/api/skills/fineMotorBOT2").reply((config) => {
  return [200, skillsDB.fineMotorBOT2];
});

Mock.onGet("/api/skills/fineMotorPDMS2").reply((config) => {
  return [200, skillsDB.fineMotorPDMS2];
});

import history from "@history.js";
import { createSlice } from "@reduxjs/toolkit";
import localStorageService from "../../services/localStorageService";
import jwtAuthService from "../../services/jwtAuthService";
import FirebaseAuthService from "../../services/firebase/firebaseAuthService";
import routes from "../../routes";

const initialState = {
  isAuthenticated: false,
  accessToken: undefined,
  success: false,
  loading: false,
  error: {
    username: null,
    password: null,
  },
  errorMessage: null,
  user: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.token;
      state.isAuthenticated = true;
    },

    userLoggedIn: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
    },

    userLoggedOut: (state) => {
      state.accessToken = undefined;
      state.user = undefined;
    },

    resetPassword: (state, action) => {
      state.success = true;
      state.loading = false;
      console.log(action.payload.email);
    },

    requestStart: (state, action) => {
      state.loading = true;
    },

    loginWithEmailAndPassword: (state, action) => {
      state.user = action.payload;
      state.accessToken = state.user.token;
      state.isAuthenticated = true;
      // state.success = true;
      // state.loading = false;

      localStorageService.setItem("auth_user", {
        accessToken: state.accessToken,
        user: state.user,
      });

      routes.navigate("/dashboard");
    },

    firebaseLoginEmailPassword: (state, action) => {
      const { email, password } = action.payload;
      state.loading = true;

      FirebaseAuthService.signInWithEmailAndPassword(email, password)
        .then((user) => {
          if (user) {
            state.user = {
              ...user,
              userId: "1",
              role: "ADMIN",
              displayName: "Watson Joyce",
              email: "watsonjoyce@gmail.com",
              photoURL: "/assets/images/face-7.jpg",
              age: 25,
              token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh",
            };

            history.push({ pathname: "/" });

            state.success = true;
            state.loading = false;
          } else {
            state.success = false;
            state.loading = false;
          }
        })
        .catch((error) => {
          state.success = false;
          state.loading = false;
          state.error = error.data;
        });
    },
    logoutJWTUser: (state) => {
      console.log(state.user);
      jwtAuthService.logout();
      state.user = undefined;
      routes.navigate("/session/signin");
    },
  },
});

export const {
  setUserData,
  userLoggedIn,
  userLoggedOut,
  resetPassword,
  logoutJWTUser,
  loginWithEmailAndPassword,
  firebaseLoginEmailPassword,
} = authSlice.actions;
export default authSlice.reducer;

import Mock from "../mock";
import shortid from "shortid";

const templateDB = {
  templates: [
    //   {
    //   id: shortid.generate(),
    //   title: "Skills Template",
    //   type: "Assessment",
    //   template: [
    //     {
    //       description: "Mood/Attitude",
    //       criteria: "Options",
    //       skill: "Psychosocial",
    //       options: "High mood,Low mood,Fluctuating mood,Irritated mood",
    //     },
    //     {
    //       description: "Communication",
    //       criteria: "Options",
    //       skill: "Psychosocial",
    //       options:
    //         "Non effective,Effective,Requires support,Restricted communication",
    //     },
    //     {
    //       description: "Attention span",
    //       criteria: "Duration",
    //       skill: "Psychosocial",
    //     },
    //     {
    //       description: "Following directions",
    //       skill: "Psychosocial",
    //       criteria: "Options",
    //       options:
    //         "Yes,No,Needs support,1 to 3 instructions,More than 3 instructions",
    //     },
    //     {
    //       description: "Sensory Seeking",
    //       skill: "Sensory processing",
    //       criteria: "Options",
    //       options: "Yes,No",
    //       requireNotes: true,
    //     },
    //     {
    //       description: "Sensory Avoiding",
    //       skill: "Sensory processing",
    //       criteria: "Options",
    //       options: "Yes,No",
    //       requireNotes: true,
    //     },
    //     {
    //       description: "Regulation",
    //       skill: "Sensory processing",
    //       criteria: "Options",
    //       options: "Regulated,Disregulated,Needs coregulation",
    //     },
    //     {
    //       description: "Hand Dominance",
    //       skill: "Fine motor coordination",
    //       criteria: "Options",
    //       options: "Left,Right,Both",
    //     },
    //     {
    //       description: "Object manipulation",
    //       skill: "Fine motor coordination",
    //       criteria: "Options",
    //       options:
    //         "Simple rotation,Complex rotation,90 degree rotation,Palm to finger,Finger to palm",
    //     },
    //     {
    //       description: "Sitting/standing posture",
    //       skill: "Fine motor coordination",
    //       criteria: "Options",
    //       options: "Good postural control,Poor postural control,Requires support",
    //     },
    //     {
    //       description: "Grasp",
    //       skill: "Fine motor coordination",
    //       criteria: "Options",
    //       options: "palmar,dynamic,tripodal,bilateral,radial,inch",
    //     },
    //     {
    //       description: "Scissor use",
    //       skill: "Fine motor coordination",
    //       criteria: "Options",
    //       options: "Age appropriate,Not age appropriate,Requires assistance",
    //     },
    //     {
    //       description: "Balance",
    //       skill: "Gross motor coordination",
    //       criteria: "Options",
    //       options:
    //         "Balanced,Requires assistance,Risk of fall,Can't maintain balance",
    //     },
    //     {
    //       description: "Bilateral coordination",
    //       skill: "Gross motor coordination",
    //       criteria: "Options",
    //       options:
    //         "Presented,Not presented,Left better than right,Right better than left",
    //     },
    //     {
    //       description: "Range of motion",
    //       skill: "Gross motor coordination",
    //       criteria: "Options",
    //       options: "",
    //     },
    //     {
    //       description: "Strength",
    //       skill: "Gross motor coordination",
    //       criteria: "Options",
    //       options: "",
    //     },
    //     {
    //       description: "Muscle tone",
    //       skill: "Gross motor coordination",
    //       criteria: "Options",
    //       options:
    //         "Spastic fliction,Spastic extention,Hibotonic,Fluctuating tone",
    //     },
    //     {
    //       description: "Eye-hand coordination",
    //       skill: "Gross motor coordination",
    //       criteria: "Options",
    //       options: "Presented,Not presented,Needs assistance",
    //     },
    //     {
    //       description: "Visual Perception",
    //       skill: "Gross motor coordination",
    //       criteria: "Options",
    //       options:
    //         "Presented,Not presented,Needs assistance,Requires further detailed assessment",
    //     },
    //     {
    //       description: "Motor planning",
    //       skill: "Gross motor coordination",
    //       criteria: "Options",
    //       options: "Appropriate,Inappropriate,Requires assistance",
    //     },
    //     {
    //       description: "Visual focus",
    //       skill: "Gross motor coordination",
    //       criteria: "Numeric",
    //     },
    //     {
    //       description: "Hygiene",
    //       skill: "Self-care",
    //       criteria: "Options",
    //       options:
    //         "Dependant,Independant,Requires assistance,Requires adaptive tool",
    //     },
    //     {
    //       description: "Shoe-tying",
    //       skill: "Self-care",
    //       criteria: "Options",
    //       options:
    //         "Dependant,Independant,Requires assistance,Requires adaptive tool",
    //     },
    //     {
    //       description: "Eating",
    //       skill: "Self-care",
    //       criteria: "Options",
    //       options:
    //         "Dependant,Independant,Requires assistance,Requires adaptive tool",
    //     },
    //     {
    //       description: "Dressing",
    //       skill: "Self-care",
    //       criteria: "Options",
    //       options:
    //         "Dependant,Independant,Requires assistance,Requires adaptive tool",
    //     },
    //     {
    //       description: "Undressing",
    //       skill: "Self-care",
    //       criteria: "Options",
    //       options:
    //         "Dependant,Independant,Requires assistance,Requires adaptive tool",
    //     },
    //     {
    //       description: "Toilet",
    //       skill: "Self-care",
    //       criteria: "Options",
    //       options:
    //         "Dependant,Independant,Requires assistance,Requires adaptive tool",
    //     },
    //     {
    //       description: "Brushing teeth",
    //       skill: "Self-care",
    //       criteria: "Options",
    //       options:
    //         "Dependant,Independant,Requires assistance,Requires adaptive tool",
    //     },
    //   ],
    // },
    {
      id: "BRMLs5MCz1K",
      title: "Template 1",
      type: "Assessment",
      assessment: "fineMotorBOT2",
      template: [
        {
          type: "multiple",
          section: "Intro",
          label:
            "Was the student able to complete the standardized assessment?",
          options: "Yes",
          _code: "fineMotorBOT2_success",
        },
        {
          type: "multiple",
          section: "Intro",
          label: "Why couldn't the student complete a standardized assessment?",
          options:
            "Refused;Couldn't follow standardized questions and directions;Didn't understand the expectations of the tasks",
          _code: "fineMotorBOT2_failure_reason",
        },
        {
          type: "radio",
          section: "Intro",
          label: "Total Motor Composite",
          options:
            "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
          _code: "total_motor_composite",
        },
        {
          type: "radio",
          section: "Intro",
          label: "(BOT-2) Fine Motor Composite",
          options:
            "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
          _code: "fine_motor_composite",
        },
        {
          type: "radio",
          section: "Fine Manual Control",
          label: "(BOT-2) Fine Manual Control",
          options:
            "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
          _code: "fine_manual_control",
        },
        {
          type: "radio",
          section: "Fine Manual Control",
          label: "(BOT-2) Fine Motor Precision subtest",
          options:
            "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
          _code: "fine_motor_precision",
        },
        {
          type: "multiple",
          section: "Fine Manual Control",
          label: "(BOT-2) Color in shape",
          options: "Filling in a circle;Filling in a star",
          _code: "shape_coloring",
          itemAnswer: "Able;Unable",
        },
        {
          type: "multiple",
          section: "Fine Manual Control",
          label: "(BOT-2) Draw a line",
          options: "Crooked line;Curved line",
          _code: "line_drawing",
          itemAnswer: "Able;Unable",
        },
        {
          type: "multiple",
          section: "Fine Manual Control",
          label: "(BOT-2) Other",
          options: "Connecting dots;Folding paper;Cutting circle",
          _code: "bot2_other",
          itemAnswer: "Able;Unable",
        },
        {
          type: "radio",
          section: "Fine Manual Control",
          label: "(BOT-2) Fine Motor Integration subtest",
          options:
            "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
          _code: "fine_motor_integration_subtest",
        },
        {
          type: "multiple",
          section: "Fine Manual Control",
          label: "(BOT-2) Copying",
          options:
            "Circle;Square;Overlapping circles;Wavy line;Triangle;Diamond;Star;Overlapping Pencils",
          _code: "copying_strengths",
          itemAnswer: "Able;Unable",
        },
        {
          type: "multiple",
          section: "Manual Coordination",
          label: "(BOT-2) Manual Coordination",
          options:
            "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
          _code: "manual_coordination",
        },
        {
          type: "multiple",
          section: "Manual Coordination",
          label: "(BOT-2) Manual Dexterity subtest",
          options:
            "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
          _code: "manual_dexterity_subtest",
        },
        {
          type: "multiple",
          section: "Manual Coordination",
          label: "(BOT-2) Manual Dexterity",
          options:
            "Making Dots;Transferring Pennies;Pegs in pegboard;Sorting cards;Stringing blocks",
          _code: "manual_dexterity_strengths",
          itemAnswer: "Able;Unable",
        },
        {
          type: "radio",
          section: "Manual Coordination",
          label: "(BOT-2) Upper-Limb Coordination subtest",
          options:
            "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
          _code: "upper_link_coordination",
        },
        {
          type: "multiple",
          section: "Manual Coordination",
          label: "(BOT-2) Upper-Limb Coordination",
          options:
            "Dropping/catching ball both hands;Catching a tossed ball;Drop/catching ball one hand;Tossed ball one hand;Dribbling one hand;Dribbling alternating;Throwing a ball",
          _code: "upper_link_coordination_strengths",
          itemAnswer: "Able;Unable",
        },
        {
          type: "radio",
          section: "Body Coordination",
          label: "(BOT-2) Body Coordination",
          options:
            "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
          _code: "body_coordination",
        },
        {
          type: "radio",
          section: "Body Coordination",
          label: "(BOT-2) Bilateral Coordination",
          options:
            "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
          _code: "bilateral_coordination",
        },
        {
          type: "multiple",
          section: "Body Coordination",
          label: "(BOT-2) Bilateral Coordination",
          options:
            "Jumping in place - opposite sides synchronized;Tapping feet and fingers- opposite side;Pivoting thumbs and index fingers;Jumping jacks;Jumping in place - same side;Touching nose eyes closed;Tapping feet and fingers - same side",
          _code: "bilateral_coordination_strength",
          itemAnswer: "Able;Unable",
        },
        {
          type: "radio",
          section: "Body Coordination",
          label: "(BOT-2) Balance Subtest",
          options:
            "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
          _code: "Balance",
        },
        {
          type: "multiple",
          section: "Body Coordination",
          label: "(BOT-2) Balance",
          options:
            "Walking on a line;Walking on a balance beam;Standing on one leg - eyes open;Standing on one leg - eyes closed;Standing on one leg balance beam - eyes open;Standing on one leg balance beam - eyes closed",
          _code: "Balance_strength",
          itemAnswer: "Able;Unable",
        },
        {
          type: "radio",
          section: "Strength and Agility",
          label: "(BOT-2) Strength and Agility",
          options:
            "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
          _code: "agility",
        },
        {
          type: "radio",
          section: "Strength and Agility",
          label: "(BOT-2) Running Speed and Agility",
          options:
            "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
          _code: "running_speed",
        },
        {
          type: "multiple",
          section: "Strength and Agility",
          label: "(BOT-2) Running Speed",
          options:
            "One-legged side hop;Two-legged side hop;One-legged stationary hop;Shuttle run;Stepping sideways over a beam",
          _code: "running_speed_strength",
          itemAnswer: "Able;Unable",
        },
        {
          type: "radio",
          section: "Strength and Agility",
          label: "(BOT-2) Running Subtest",
          options:
            "Well-Above Average;Above Average;Average;Below Average;Well-Below Average",
          _code: "strength_subtest",
        },
        {
          type: "multiple",
          section: "Strength and Agility",
          label: "(BOT-2) Agility",
          options:
            "Push-ups;Knee push-ups;Sit-ups;Push-ups and sit-ups;Jump forward",
          _code: "bot2_strength",
          itemAnswer: "Able;Unable",
        },
      ],
    },
  ],
};

Mock.onGet("/api/template/all").reply((config) => {
  // console.log("test 2");

  return [200, templateDB.templates];
});

Mock.onGet("/api/template").reply((config) => {
  const id = config.data;
  const response = templateDB.templates.find((template) => template.id === id);
  return [200, response];
});

Mock.onPost("/api/template/delete").reply((config) => {
  let template = JSON.parse(config.data);
  let index = { i: 0 };
  templateDB.templates.forEach((element) => {
    if (element.id === template.id) {
      return [200, templateDB.templates.splice(index.i, 1)];
    }
    index.i++;
  });
  return [200, templateDB.templates];
});

Mock.onPost("/api/template/update").reply((config) => {
  console.log(config);

  let template = JSON.parse(config.data);
  let index = { i: 0 };

  console.log("id: " + template.id);
  console.log("title: " + template.title);

  templateDB.templates.forEach((element) => {
    if (element.id === template.id) {
      templateDB.templates[index.i] = template;
      return [200, templateDB.templates];
    }
    index.i++;
  });
  return [200, templateDB.templates];
});

Mock.onPost("/api/template/add").reply((config) => {
  let template = JSON.parse(config.data);
  template.id = shortid.generate();
  templateDB.templates.push(template);
  return [200, templateDB.templates];
});

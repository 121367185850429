import Mock from "../mock";
import shortid from "shortid";

const activitiesDB = {
  activities: [
    {
      id: "9FLgZ",
      patientId: "9FLgZBF0LQX",
      templateId: "BRMLs5MCz1K",
      date: "2024-01-25",
      title: "test 1",
      results: {
        fineMotorBOT2_success: { answer: "Yes" },
        fineMotorBOT2_failure_reason: "",
        total_motor_composite: { answer: "Below Average" },
        fine_motor_composite: { answer: "Above Average" },
        fine_manual_control: { answer: "Above Average" },
        fine_motor_precision: { answer: "Above Average" },
        shape_coloring: {
          answer: [
            { "Filling in a circle": "Unable" },
            { "Filling in a star": "Unable" },
          ],
        },
        line_drawing: {
          answer: [{ "Crooked line": "Unable" }, { "Curved line": "Unable" }],
        },
        bot2_other: {
          answer: [
            { "Connecting dots": "Able" },
            { "Folding paper": "Able" },
            { "Cutting circle": "Able" },
          ],
        },
        fine_motor_integration_subtest: { answer: "Average" },
        copying_strengths: {
          answer: [{ Circle: "Unable" }, { Triangle: "Unable" }],
        },
        manual_coordination: { answer: "Below Average" },
        manual_dexterity_subtest: { answer: "Average" },
        manual_dexterity_strengths: {
          answer: [{ "Making Dots": "Unable" }, { "Sorting cards": "Unable" }],
        },
        upper_link_coordination: { answer: "Below Average" },
        upper_link_coordination_strengths: {
          answer: [
            { "Catching a tossed ball": "Unable" },
            { "Tossed ball one hand": "Unable" },
          ],
        },
        body_coordination: { answer: "Below Average" },
        bilateral_coordination: { answer: "Well-Below Average" },
        bilateral_coordination_strength: {
          answer: [
            { "Jumping jacks": "Unable" },
            { "Jumping in place - same side": "Unable" },
          ],
        },
        Balance: { answer: "Well-Below Average" },
        Balance_strength: {
          answer: [
            { "Walking on a line": "Able" },
            { "Standing on one leg - eyes open": "Unable" },
          ],
        },
        agility: { answer: "Well-Below Average" },
        running_speed: { answer: "Well-Below Average" },
        running_speed_strength: {
          answer: [{ "Two-legged side hop": "Unable" }],
        },
        strength_subtest: { answer: "Below Average" },
        bot2_strength: {
          answer: [{ "Push-ups": "Unable" }, { "Sit-ups": "Unable" }],
        },
      },
    },
    {
      id: "9FLgA",
      patientId: "9FLgZBF0LQX",
      templateId: "BRMLs5MCz1K",
      date: "2024-01-27",
      title: "test 2",
      results: {
        fineMotorBOT2_success: { answer: "Yes" },
        fineMotorBOT2_failure_reason: "",
        total_motor_composite: { answer: "Below Average" },
        fine_motor_composite: { answer: "Above Average" },
        fine_manual_control: { answer: "Above Average" },
        fine_motor_precision: { answer: "Above Average" },
        shape_coloring: {
          answer: [
            { "Filling in a circle": "Unable" },
            { "Filling in a star": "Unable" },
          ],
        },
        line_drawing: {
          answer: [{ "Crooked line": "Unable" }, { "Curved line": "Unable" }],
        },
        bot2_other: {
          answer: [
            { "Connecting dots": "Able" },
            { "Folding paper": "Able" },
            { "Cutting circle": "Able" },
          ],
        },
        fine_motor_integration_subtest: { answer: "Average" },
        copying_strengths: {
          answer: [{ Circle: "Unable" }, { Triangle: "Unable" }],
        },
        manual_coordination: { answer: "Below Average" },
        manual_dexterity_subtest: { answer: "Average" },
        manual_dexterity_strengths: {
          answer: [{ "Making Dots": "Unable" }, { "Sorting cards": "Unable" }],
        },
        upper_link_coordination: { answer: "Below Average" },
        upper_link_coordination_strengths: {
          answer: [
            { "Catching a tossed ball": "Unable" },
            { "Tossed ball one hand": "Unable" },
          ],
        },
        body_coordination: { answer: "Below Average" },
        bilateral_coordination: { answer: "Well-Below Average" },
        bilateral_coordination_strength: {
          answer: [
            { "Jumping jacks": "Unable" },
            { "Jumping in place - same side": "Unable" },
          ],
        },
        Balance: { answer: "Well-Below Average" },
        Balance_strength: {
          answer: [
            { "Walking on a line": "Able" },
            { "Standing on one leg - eyes open": "Unable" },
          ],
        },
        agility: { answer: "Well-Below Average" },
        running_speed: { answer: "Well-Below Average" },
        running_speed_strength: {
          answer: [{ "Two-legged side hop": "Unable" }],
        },
        strength_subtest: { answer: "Below Average" },
        bot2_strength: {
          answer: [{ "Push-ups": "Unable" }, { "Sit-ups": "Unable" }],
        },
      },
    },
    {
      id: "9FLgB",
      patientId: "9FLgZBF0LQX",
      templateId: "BRMLs5MCz1K",
      date: "2024-01-29",
      title: "test 3",
      results: {
        fineMotorBOT2_success: { answer: "Yes" },
        fineMotorBOT2_failure_reason: "",
        total_motor_composite: { answer: "Below Average" },
        fine_motor_composite: { answer: "Above Average" },
        fine_manual_control: { answer: "Above Average" },
        fine_motor_precision: { answer: "Above Average" },
        shape_coloring: {
          answer: [
            { "Filling in a circle": "Unable" },
            { "Filling in a star": "Unable" },
          ],
        },
        line_drawing: {
          answer: [{ "Crooked line": "Unable" }, { "Curved line": "Unable" }],
        },
        bot2_other: {
          answer: [
            { "Connecting dots": "Able" },
            { "Folding paper": "Able" },
            { "Cutting circle": "Able" },
          ],
        },
        fine_motor_integration_subtest: { answer: "Average" },
        copying_strengths: {
          answer: [{ Circle: "Unable" }, { Triangle: "Unable" }],
        },
        manual_coordination: { answer: "Below Average" },
        manual_dexterity_subtest: { answer: "Average" },
        manual_dexterity_strengths: {
          answer: [{ "Making Dots": "Unable" }, { "Sorting cards": "Unable" }],
        },
        upper_link_coordination: { answer: "Below Average" },
        upper_link_coordination_strengths: {
          answer: [
            { "Catching a tossed ball": "Unable" },
            { "Tossed ball one hand": "Unable" },
          ],
        },
        body_coordination: { answer: "Below Average" },
        bilateral_coordination: { answer: "Well-Below Average" },
        bilateral_coordination_strength: {
          answer: [
            { "Jumping jacks": "Unable" },
            { "Jumping in place - same side": "Unable" },
          ],
        },
        Balance: { answer: "Well-Below Average" },
        Balance_strength: {
          answer: [
            { "Walking on a line": "Able" },
            { "Standing on one leg - eyes open": "Unable" },
          ],
        },
        agility: { answer: "Well-Below Average" },
        running_speed: { answer: "Well-Below Average" },
        running_speed_strength: {
          answer: [{ "Two-legged side hop": "Unable" }],
        },
        strength_subtest: { answer: "Below Average" },
        bot2_strength: {
          answer: [{ "Push-ups": "Unable" }, { "Sit-ups": "Unable" }],
        },
      },
    },
    {
      id: "9FLgC",
      patientId: "9FLgZBF0LQX",
      templateId: "BRMLs5MCz1K",
      date: "2024-01-31",
      title: "test 4",
      results: {
        fineMotorBOT2_success: { answer: "Yes" },
        fineMotorBOT2_failure_reason: "",
        total_motor_composite: { answer: "Below Average" },
        fine_motor_composite: { answer: "Above Average" },
        fine_manual_control: { answer: "Above Average" },
        fine_motor_precision: { answer: "Above Average" },
        shape_coloring: {
          answer: [
            { "Filling in a circle": "Unable" },
            { "Filling in a star": "Unable" },
          ],
        },
        line_drawing: {
          answer: [{ "Crooked line": "Unable" }, { "Curved line": "Unable" }],
        },
        bot2_other: {
          answer: [
            { "Connecting dots": "Able" },
            { "Folding paper": "Able" },
            { "Cutting circle": "Able" },
          ],
        },
        fine_motor_integration_subtest: { answer: "Average" },
        copying_strengths: {
          answer: [{ Circle: "Unable" }, { Triangle: "Unable" }],
        },
        manual_coordination: { answer: "Below Average" },
        manual_dexterity_subtest: { answer: "Average" },
        manual_dexterity_strengths: {
          answer: [{ "Making Dots": "Unable" }, { "Sorting cards": "Unable" }],
        },
        upper_link_coordination: { answer: "Below Average" },
        upper_link_coordination_strengths: {
          answer: [
            { "Catching a tossed ball": "Unable" },
            { "Tossed ball one hand": "Unable" },
          ],
        },
        body_coordination: { answer: "Below Average" },
        bilateral_coordination: { answer: "Well-Below Average" },
        bilateral_coordination_strength: {
          answer: [
            { "Jumping jacks": "Unable" },
            { "Jumping in place - same side": "Unable" },
          ],
        },
        Balance: { answer: "Well-Below Average" },
        Balance_strength: {
          answer: [
            { "Walking on a line": "Able" },
            { "Standing on one leg - eyes open": "Unable" },
          ],
        },
        agility: { answer: "Well-Below Average" },
        running_speed: { answer: "Well-Below Average" },
        running_speed_strength: {
          answer: [{ "Two-legged side hop": "Unable" }],
        },
        strength_subtest: { answer: "Below Average" },
        bot2_strength: {
          answer: [{ "Push-ups": "Unable" }, { "Sit-ups": "Unable" }],
        },
      },
    },
    {
      id: "9FLgD",
      patientId: "9FLgZBF0LQX",
      templateId: "BRMLs5MCz1K",
      date: "2024-02-02",
      title: "test 5",
      results: {
        fineMotorBOT2_success: { answer: "Yes" },
        fineMotorBOT2_failure_reason: "",
        total_motor_composite: { answer: "Below Average" },
        fine_motor_composite: { answer: "Above Average" },
        fine_manual_control: { answer: "Above Average" },
        fine_motor_precision: { answer: "Above Average" },
        shape_coloring: {
          answer: [
            { "Filling in a circle": "Unable" },
            { "Filling in a star": "Unable" },
          ],
        },
        line_drawing: {
          answer: [{ "Crooked line": "Unable" }, { "Curved line": "Unable" }],
        },
        bot2_other: {
          answer: [
            { "Connecting dots": "Able" },
            { "Folding paper": "Able" },
            { "Cutting circle": "Able" },
          ],
        },
        fine_motor_integration_subtest: { answer: "Average" },
        copying_strengths: {
          answer: [{ Circle: "Unable" }, { Triangle: "Unable" }],
        },
        manual_coordination: { answer: "Below Average" },
        manual_dexterity_subtest: { answer: "Average" },
        manual_dexterity_strengths: {
          answer: [{ "Making Dots": "Unable" }, { "Sorting cards": "Unable" }],
        },
        upper_link_coordination: { answer: "Below Average" },
        upper_link_coordination_strengths: {
          answer: [
            { "Catching a tossed ball": "Unable" },
            { "Tossed ball one hand": "Unable" },
          ],
        },
        body_coordination: { answer: "Below Average" },
        bilateral_coordination: { answer: "Well-Below Average" },
        bilateral_coordination_strength: {
          answer: [
            { "Jumping jacks": "Unable" },
            { "Jumping in place - same side": "Unable" },
          ],
        },
        Balance: { answer: "Well-Below Average" },
        Balance_strength: {
          answer: [
            { "Walking on a line": "Able" },
            { "Standing on one leg - eyes open": "Unable" },
          ],
        },
        agility: { answer: "Well-Below Average" },
        running_speed: { answer: "Well-Below Average" },
        running_speed_strength: {
          answer: [{ "Two-legged side hop": "Unable" }],
        },
        strength_subtest: { answer: "Below Average" },
        bot2_strength: {
          answer: [{ "Push-ups": "Unable" }, { "Sit-ups": "Unable" }],
        },
      },
    },
  ],
};

Mock.onGet("/api/activity/all").reply((config) => {
  const id = config.data;
  const from = config.from;
  const to = config.to;

  const response = activitiesDB.activities.filter(
    (activity) =>
      activity.patientId === id &&
      (!from || activity.date >= from) &&
      (!to || activity.date <= to)
  );
  return [200, response];
});

Mock.onGet("/api/activity").reply((config) => {
  const id = config.data;
  const response = activitiesDB.activities.find(
    (activity) => activity.id === id
  );
  return [200, response];
});

Mock.onPost("/api/activity/delete").reply((config) => {
  let activity = JSON.parse(config.data);
  let index = { i: 0 };
  activitiesDB.activities.forEach((element) => {
    if (element.id === activity.id) {
      return [200, activitiesDB.activities.splice(index.i, 1)];
    }
    index.i++;
  });
  return [200, activitiesDB.activities];
});

Mock.onPost("/api/activity/update").reply((config) => {
  console.log(config);

  let activity = JSON.parse(config.data);
  let index = { i: 0 };

  activitiesDB.activities.forEach((element) => {
    if (element.id === activity.id) {
      activitiesDB.activities[index.i] = activity;
      return [200, activitiesDB.activities];
    }
    index.i++;
  });
  return [200, activitiesDB.activities];
});

Mock.onPost("/api/activity/add").reply((config) => {
  let activity = JSON.parse(config.data);
  activity.id = shortid.generate();
  activitiesDB.activities.push(activity);
  return [200, activitiesDB.activities];
});

Mock.onDelete("/api/activity").reply((config) => {
  const id = config.data;

  activitiesDB.activities = activitiesDB.activities.filter(
    (activity) => activity.id !== id
  );

  console.log(activitiesDB.activities.length);
  return [200, "Activity removed successfully!!"];
});

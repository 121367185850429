function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function formatDate(date) {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");
}

function calculateAge(birthday) {
  // birthday is a date
  var ageDifMs = Date.now() - Date.parse(birthday);
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  var age = Math.abs(ageDate.getUTCFullYear() - 1970);

  if(age <= 6) return "CHILD";
  else if(age <= 18) return "SCHOOL";
  else return "ADULT";
}

function isNumber(value) {
  return typeof value === "number" && isFinite(value);
}

function stringIsNumber(value) {
  return !isNaN(value);
}

function checkIfBoolean(value) {
  return value === 'true' ? true : (value === 'false' ? false : value);
}

function isBoolean(value) {
  return value === true || value === false;
}

const getAnswer = (o) => {
  return o.itemsAnswer && o.itemsAnswer.length > 0
    ? o.itemsAnswer
    : o.textAnswer
    ? o.textAnswer
    : o.dateAnswer
    ? o.dateAnswer
    : o.longAnswer
    ? o.longAnswer
    : o.doubleAnswer
    ? o.doubleAnswer
    : o.value;
};

const utils = { formatDate, calculateAge, isNumber, stringIsNumber, checkIfBoolean, isBoolean, getAnswer };

export default utils;

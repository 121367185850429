import Mock from "../mock";
import shortid from "shortid";

const patientsDB = {
  patients: [
    {
      id: "9FLgZBF0LQX",
      patient_name: "Test name",
      birth: "2024-02-01",
      current_diagnosis: { answer: "Specific Learning Disability (SLD)" },
      sex: { answer: "Male" },
      hair_color: "Black",
      eye_color: "Brown",
      Stated_Height: "1.74",
      actual_Height: "1.75",
      Stated_Weight: "75",
      Actual_Weight: "76",
      referral_OT: { answer: "Yes" },
      referralBy: "test referred by",
      dateReferred: "2024-02-02",
      infoSource: { answer: "Patient" },
      broughtBy: { answer: "Family" },
      primaryLanguage: { answer: "Understands" },
      secondaryLanguage: { answer: "Understands" },
      religiousPreference: "test religious",
      religiousPractices: "test practices",
      culturalBeliefs: "test beliefs",
      allergies: "test allergies",
      alcohol_use: { answer: "Yes" },
      alcohol_amount: "1 bottle",
      alcohol_Frequency: "weekly",
      drugs_use: { answer: "No" },
      drugs_Substance: "",
      drugs_amount: "",
      drugs_Frequency: "",
      medication_list: "",
      communicableDiseases: { answer: "TB" },
      medicalHistory: "test medical",
      chronicIllness: "test chronic",
      surgicalHistory: "test surgical",
      vision: { answer: "Normal" },
      hearing: { answer: "Normal" },
      speech: { answer: "Normal" },
      olfactory: "test olfactory",
      tactile: "test tactile",
      motor: "test motor",
      Maladaptive: {
        answer: "Poor communication skills",
        comments: "test comment",
        other: "test other",
      },
      stressors: "test stressors",
      stressorsResponse: "test response to stressors",
      knownTriggers: "test violence",
      safety: "test emergency plan",
      attentionSpan: "30",
      direction: "test instruction",
      initiationA: "test talking",
      initiationB: "test task",
      problemSolving: "test problem solving",
      dailyActivities: "test intefere",
      suicideRisk: { answer: "Not at risk" },
      assaultRisk: { answer: "Witnessed assault" },
      elopementRisk: {
        answer: "Refuse hospitalization, verbalize need to leave",
      },
      hypersexualRisk: { answer: "Exposes self, openly masturbates" },
      fireRisk: { answer: "Confused, disoriented smoker" },
      toxicityRisk: {
        answer: "10 points increase in pulse or mean blood pressure",
      },
      seizuresRisk: { answer: "History of seizures" },
      fallRisk: { answer: "Age 70 or above" },
      familyMental: "test suicide",
      familySocial: "test social",
      supportSystem: "test support",
      supportSystemPhone: "test support phone",
      contactPerson: "test contact",
      contactPersonPhone: "test contact phone",
      educationLevel: "test education",
      educationFormal: "test formal",
      educationInformal: "test informal",
      domesticAbuseSigns: { answer: "No" },
      unsafeHome: { answer: "Yes" },
      threat: { answer: "No" },
      highRiskViolence: { answer: "Yes" },
      highRiskAbuse: { answer: "No" },
      transporationMethod: "car",
      drivingHistory: "testing driving",
      licenseRevoked: { answer: "Yes" },
      makeChange: { answer: "Needs assistance" },
      absenceMoney: { answer: "No" },
      sleeps: "6",
      sleepingPattern: { answer: "Difficulty falling asleep" },
      sleepAid: { answer: "Yes" },
      rested: { answer: "No" },
      jobs: "2",
      lastJob: "test job",
      longestEmployment: "2 years",
      lastWorkedDate: "2024-01-29",
      workInterruption: "test school interruption",
      leisurePursuit: "test leisure",
      daytime: "test management",
      useResources: "test knowledge",
      routineChange: { answer: "No" },
      additionalEvaluation: { answer: "Independent Living" },
      recommendations: "test OT",
      toBeSeen: "1",
      toBeSeenFor: "3",
      toBeSeenWeek: "4",
      toBeSeenMode: { answer: "Individual" },
      referrals: "test referrals",
      interpreterName: "test interpreter",
      otrName: "test OTR",
    },
  ],
};

Mock.onGet("/api/patient/all").reply((config) => {
  // console.log("test 2");

  return [200, patientsDB.patients];
});

Mock.onGet("/api/patient").reply((config) => {
  const id = config.data;
  const response = patientsDB.patients.find((p) => p.id === id);
  return [200, response];
});

Mock.onPost("/api/patient/delete").reply((config) => {
  let patient = JSON.parse(config.data);
  let index = { i: 0 };
  patientsDB.patients.forEach((element) => {
    if (element.id === patient.id) {
      return [200, patientsDB.patients.splice(index.i, 1)];
    }
    index.i++;
  });
  return [200, patientsDB.patients];
});

Mock.onPost("/api/patient/update").reply((config) => {
  console.log(config);

  let patient = JSON.parse(config.data);
  let index = { i: 0 };

  console.log("id: " + patient.id);

  patientsDB.patients.forEach((element) => {
    if (element.id === patient.id) {
      patientsDB.patients[index.i] = patient;
      return [200, patientsDB.patients];
    }
    index.i++;
  });
  return [200, patientsDB.patients];
});

Mock.onPost("/api/patient/add").reply((config) => {
  let patient = JSON.parse(config.data);
  patient.id = shortid.generate();
  patientsDB.patients.push(patient);
  return [200, patientsDB.patients];
});

import axios from "axios";
import { originalAdapter } from "../../fake-db/mock";
import localStorageService from "./localStorageService";
import {encode as base64_encode} from 'base-64';
import { SERVER } from "../properties";
import routes from "../routes";

axios.defaults.adapter = originalAdapter;

class JwtAuthService {
  user = {
    userId: "1",
    role: "EDITOR",
    displayName: "Watson Joyce",
    email: "watsonjoyce@gmail.com",
    photoURL: "/assets/images/face-7.jpg",
    age: 25,
    token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh",
  };

  loginWithEmailAndPassword = (email, password) => {
    const body = base64_encode(email + ":" + password);
    console.log(email + ":" + password);
    console.log(body);

    return new Promise((resolve, reject) => {
      axios.post(SERVER + "authenticate/jwt/login", body).then((response) => {
        console.log(response);
        console.log(response.headers.token);

        this.setSession(response.headers.token);
        this.setUser(response.data.user);

        let user = response.data.user;
        user.token = response.headers.token;

        resolve(user);
      });
    });
  };

  loginWithToken = (() => {
    let cachedPromise = null; // Stores the resolved promise
  
    return (token, user) => {
      console.log(token);
  
      if (cachedPromise) return cachedPromise;
      
      cachedPromise = new Promise((resolve, reject) => {
        axios.post(SERVER + "authenticate/jwt/validate", token).then((response) => {
          console.log(response.headers.token);

          if (response.headers.token) {
            user.token = response.headers.token;
            this.setSession(response.headers.token);

            if (token !== response.headers.token) resolve(user);
            else resolve(null);
          } else {
            routes.navigate("/sessions/signin");
          }
        }).catch((error) => {
          routes.navigate("/sessions/signin");
        });
      });
  
      return cachedPromise;
    };
  })();

  logout = () => {
    axios.get(SERVER + "authenticate/jwt/logout");

    this.setSession(null);
    this.removeUser();
  };

  setSession = (token) => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };
  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  };
  removeUser = () => {
    localStorage.removeItem("auth_user");
  };
}

const jwtAuthService = new JwtAuthService();

export default jwtAuthService;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  values: [],
  template: [],
};

const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    setAssessmentValues(state, action) {
      if(Array.isArray(action.payload)) {
        state.values = action.payload;

      } else if (action.payload && action.payload.code) {
        let n = {
          sectionCode: action.payload.code,
          value: action.payload.value
        }
        if(action.payload.comments) n["comments"] = action.payload.comments;
        if(action.payload.other) n["other"] = action.payload.other;
        
        let l;
        if(state.values.find((v) => v.sectionCode === action.payload.code)) {
          l = state.values.map(
            (v) => v.sectionCode !== action.payload.code ? v : n
          );
        } else {
          l = state.values;
          l.push(n);
        }

        state.values = l;
      } else if(!action.payload) {
        state.values = [];
      }
    },
    setAssessmentTemplate(state, action) {
      state.template = action.payload;
    },
  },
});

export const {
  setAssessmentValues,
  setAssessmentTemplate,
} = assessmentSlice.actions;

export default assessmentSlice.reducer;

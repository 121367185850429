import { createSlice } from "@reduxjs/toolkit";
import utils from "../../mvp/utils";

const initialState = {
  patient: null,
  values: {},
  form: [],
  fullForm: []
};

const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    setPatient(state, action) {
      state.patient = action.payload;
    },
    setValues(state, action) {
      if (!action.payload) {
        state.values = {};
      } else {
        let request = {...action.payload};
        
        if (
          request["otherPatientDetails"] &&
          !Array.isArray(request["otherPatientDetails"])
        ) {
          let otherPatientDetails = request["otherPatientDetails"];

          if (
            !state.values["otherPatientDetails"] ||
            state.values["otherPatientDetails"].length === 0
          ) {
            state.values["otherPatientDetails"] = [otherPatientDetails];
          } else {
            let l = state.values["otherPatientDetails"].filter(
              (v) => v.field !== otherPatientDetails.field
            );
            l.push(otherPatientDetails);
            state.values["otherPatientDetails"] = l;
          }
          
          delete request["otherPatientDetails"];
        }

        Object.assign(state.values, request);

        if(request["birth"]) {
          let age = utils.calculateAge(request["birth"]);
          console.log(age);
          state.form = state.fullForm.filter((o) => o.age === "ALL" || o.age.includes(age));
        }
      }
    },
    setFullForm(state, action) {
      state.fullForm = action.payload;
      state.form = action.payload;
    }
  },
});

export const { setPatient, setValues, setFullForm } = patientSlice.actions;

export default patientSlice.reducer;
